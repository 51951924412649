.table-percent10 {
    width: 10%;
  }
.table-percent20 {
    width: 20%;
  }
.table-percent30 {
    width: 30%;
  }
.table-percent100 {
    width: 100%;
  }

.font13 {
  font-size: 13px;
  text-align: center;
}

.text-center {
  text-align: center;
}

.blocked-bet {
  background-color: #ffcccc; /* Light red background */
}

.blocked-message {
  color: #d9534f; /* Bootstrap's danger color */
  font-style: italic;
  font-size: 0.9em;
}