.prize {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5em;
}
.prize1 {
    display: flex;
    flex-direction: row;
}

.border-bottom {
    border-bottom-style: 2 px solid #111111;
}

.item-prize {
    flex-grow: 1;
}

.item-prize-3 {
        flex: 1 1;
        box-shadow: 0 3px 6px #d4d4d4;
        padding: 5px;
        margin: 5px;
        border-radius: 10px;
        color: #333333;
        background-color: #fecc05f9;
        font-weight: 600;
}

.item-prize-left {
    width: 35%;
    text-align: left;
    padding-left: 1em;
}
.item-prize-left1 {
    width: 35%;
    text-align: center;
}
.bg-green {
    background-color: #068153;
    border-radius: 8px;
    margin: 5px;
    color: #ffffff !important;
    box-shadow: 0 3px 6px #af9d9f;
}
.bg-green1 {
    background-color: #55bf18;
    border-radius: 8px;
    margin: 5px;
    padding: 0 10px 0;
    color: #ffffff;
    box-shadow: 0 3px 6px #af9d9f;
}
.item-prize-right {
    width: 65%;
    font-weight: 600;
    /* letter-spacing: 2px; */
}
.item-prize-right1 {
    width: 65%;
    /* letter-spacing: 2px; */
}

.right-prize {
    display: flex;
    flex-direction: column;
}

.font18 {
    font-size: 18px;
    color: rgb(103,103,103);
    font-weight: 700;
}

.f14r1 {
    font-size: 14px;
    color: rgb(103,103,103);
    font-weight: 400;
}
.f18r1 {
    font-size: 18px;
    color: rgb(103,103,103);
    font-weight: 400;
    margin-bottom: 0 !important;
}

.flex-prize {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5em;
}

.iconprize2 {
    max-width: 140px;
    height: 100px;
}

.searchDate {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 0;
}

.pdl5 {
    margin-left: 20px;
}

.btn-prize1 {
    background-color: #676767;
    border-radius: 8px;
    color: #fff;
    padding: 0 10px;
    outline: none;
    border: none;
    font-size: 14px;
    margin-bottom: 5px;
    box-shadow: 0 3px 6px #af9d9f; 
}

.datepicker {
    padding-left: 10px;
    width: 180px;
    border: 1px solid #068153;
    border-radius: 8px;
}

.text-center {
    text-align: center;
}

.redblack {
    color: #5F030C;
}