@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@400;700&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Prompt", sans-serif;
}

#root {
  width: 100%;
  height: 100%;
  background: #0e2201;
}

body {
  background: rgb(239, 239, 239);
  /* min-height: 100vh;
  margin: 0px auto; */
  /* position: relative; */
}

body,
html,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Prompt", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.container {
}

.rut {
  min-height: 100vh;
  margin: 0px auto;
  position: relative;
}

.main {
  min-height: 100vh;
  max-width: 850px;
  z-index: 9;
  margin: 0px auto;
  background: rgb(239, 239, 239);
}
/* incss */
.Header {
  background: #7e0000;
  /* background: linear-gradient(180deg, #610000, #900 56%, #bd0000 86%, #d00); */
  background: linear-gradient(
    180deg,
    #214c05,
    #437c02 56%,
    #2aa500 86%,
    #57a300
  );
  position: fixed;
  width: 100%;
  padding: 10px 15px;
  max-width: 850px;
  color: #fff;
  font-size: 0.9em;
  z-index: 999;
  text-align: center;
  display: flex;
}

.flex-1-header {
  flex: 1 1;
  text-align: center;
}

.left-header {
  text-align: left;
  margin-left: 5px;
  font-size: 20px;
  cursor: pointer;
  width: 100px;
}

.margin5-header {
  margin-right: 10px;
  margin-left: 10px;
  padding: 8px 14px;
  border-radius: 30px;
  font-size: 17px;
  font-weight: 600;
  outline: none;
  background-color: #437c02;
  color: #fff;
  width: 44px;
  float: right;
  cursor: pointer;
}

.banner {
  width: 100%;
}
.layout-Login {
  color: #fff;
  padding: 0 15px;
  z-index: 99;
}
.relative-login {
  position: relative;
  min-height: 450px;
}
.relative-login1 {
  position: relative;
  min-height: 650px;
  font-size: 0.8em;
}

.container-login {
  background: #fff;
  color: #676767;
  box-shadow: 0 3px 6px #d4d4d4;
  position: absolute;
  width: 100%;
}

.login-thank1 {
  color: #214c05;
}

.item21 {
  background-color: #ffffff;
  box-shadow: 0 3px 6px #d4d4d4;
  border-radius: 10px;
}
.item22 {
  padding-top: 10px;
}
.item23 {
  padding-top: 10px;
}

.container-login {
  padding: 20px;
  border-radius: 10px;
  margin-top: -20px;
  z-index: 99 !important;
}

.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.box-login {
  margin: 20px 0;
  position: relative;
}

.flex-login {
  display: flex;
}

.logo-login {
  width: 180px;
  height: auto;
}

.logo-text-login {
  font-size: 22px;
}

.flex-center-y-login {
  -webkit-align-items: center;
  align-items: center;
}

.btn-regis-login {
  background: #2aa500;
  font-size: 16px;
  outline: none;
  color: #fff;
  padding: 10px;
  /* width: 40%; */
  border-radius: 100px;
  border: 0;
  cursor: pointer;
  width: auto;
}

.input-login {
  text-align: center;
  padding: 13px;
  width: 100%;
  outline: none;
  border-radius: 100px;
  color: #676767;
  background: #fff;
  border: 2px solid #707070;
}

.btn-login {
  font-size: 16px;
  background: #198754;
  outline: none;
  color: #fff;
  padding: 13px 10px;
  width: 100%;
  border-radius: 100px;
  border: 0;
}

.green-login {
  color: #214c05;
  cursor: pointer;
}

.green-login1 {
  font-size: 16px !important;
  font-weight: 400 !important;
}

.flex1-login {
  flex: 1 1;
}

.text-dec {
  text-decoration: none;
}

.icon-login {
  position: absolute;
  margin: 16.5px 16.5px 16.5px 20px;
}

.container-Register {
  background: #fff;
  padding: 20px;
  color: #676767;
  border-radius: 20px;
  margin-top: -20px;
  z-index: 99;
  position: absolute;
  width: 100%;
  box-shadow: 0 3px 6px #d4d4d4;
}

.relative-Register {
  position: relative;
  margin-top: -20px;
}

.top-Register {
  display: flex;
  border-bottom: 1.5px solid #707070;
  padding: 5px;
}

.title-Register {
  background-color: #214c05;
  font-size: 12.8px;
  padding: 3px 10px;
  border-radius: 5px;
  color: #fff;
}

.icon-Register {
  flex: 1 1;
  text-align: right;
}

.box-center {
  align-self: center;
  justify-self: center;
}

.box-info-Register2 {
  font-size: 12.8px;
  padding: 8px;
  margin: 5px 0;
}

.box-info-Register {
  font-size: 12.8px;
  padding: 8px;
  margin: 5px 0;
}

.info-Register {
  margin: 5px 0;
  border: 2px solid #707070;
  background-color: #fff;
  color: #676767;
  border-radius: 30px;
  padding: 10px;
  width: 100%;
  font-size: 16px;
  text-align: center;
  outline: none;
}

.btn-Register {
  display: flex;
  margin: 10px 0;
}

.layout-Register {
  padding: 10px 15px;
  color: #fff;
  /* position: relative; */
}
.btn-cancel-Register {
  -webkit-flex: 1 1;
  flex: 1 1;
  padding: 8px 0;
  margin: 5px 0;
  outline: none;
  cursor: pointer;
}

.btn-confirm-Play,
.btn-confirm-Register2 {
  -webkit-flex: 1 1;
  flex: 1 1;
  color: #fff;
  margin: 5px 0;
  outline: none;
}
.btn-confirm-Play {
  background: #2aa500;
  border: none;
  border-radius: 30px;
  padding: 8px 0;
}
.btn-confirm-Register {
  background: #214c05;
  border: none;
  color: #fff;
  border-radius: 30px;
}
.btn-cancel-Register,
.btn-confirm-Register {
  -webkit-flex: 1 1;
  flex: 1 1;
  padding: 8px 0;
  margin: 5px;
  outline: none;
}
.btn-cancel-Register {
  background: none;
  color: #214c05;
  border: 2px solid #214c05;
  border-radius: 30px;
}

/* ListLotto page*/

.layout-lotto {
  color: #fff;
  position: relative;
  padding: 10px 15px 80px;
}

.relative-lotto {
  position: relative;
  margin-top: -10px;
}

.container-lotto {
  background: #fff;
  padding: 10px 10px;
  border-radius: 10px;
  margin-top: -20px;
  z-index: 99;
  position: absolute;
  width: 100%;
  box-shadow: 0 3px 6px #d4d4d4;
}

.box-text-lotto,
.box-title-lotto {
  display: flex;
}

.box-text-lotto {
  flex-direction: row;
}

.box-text-lotto3 {
  text-align: center;
}

.box-title-lotto {
  flex: 3 1;
  align-items: center;
}

.flex-karean3 {
  width: 220px;
  height: 130px;
}

.flex-lotto3 {

}

.flag-lotto,
.flag-karean {
  width: 85px;
  height: 85px;
}

.flag-karean1 {
  width: 85px;
  height: 85px;
}

.lottoHeight {
  height: 220px;
}

.lottoHeight3 {
  height: 375px;
}

.text-karean {
  margin: 0.2rem;
  color: #437c02;
  font-size: 0.75rem;

}

.title-lotto {
  line-height: 16px;
  padding: 0 5px;
  font-weight: bold;
  margin: auto 0;
  color: #676767;
  font-size: 14px;
}

.subtitle-lotto {
  font-size: 14px;
  letter-spacing: 0;
  font-weight: lighter;
}

.box-countdown-lotto {
  background-color: #fff;
  color: #676767;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  /* border-radius: 5px;
  box-shadow: 0 3px 12px #d4d4d4; */
  /* margin: 5px 0; */
  margin: 0;
  padding: 0;
  /* padding: 5px; */
  -webkit-flex: 2 1;
  flex: 2 1;
  justify-content: center;
  font-size: 12px;
}
.box-countdown-lotto-top {
  background-color: #f6d110;
  color: #111111;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  border-radius: 7px;
  box-shadow: 0 3px 3px #626161;
  margin: 5px 2px;
  padding: 8px 2px 5px;
  -webkit-flex: 2 1;
  flex: 2 1;
  justify-content: center;
  font-size: 14px;
  text-align: center;
  /* min-width: 220px; */
}

.box-countdown-lotto-top p {
  margin-bottom: 0 !important;
}
.box-countdown-lotto-bottom {
  background-color: #fff;
  color: #2e2e2e;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  /* border-radius: 5px;
  box-shadow: 0 3px 12px #d4d4d4; */
  margin: 0px;
  padding: 0px;
  -webkit-flex: 2 1;
  flex: 2 1;
  justify-content: center;
  font-size: 12px;
}

.box-btn-lotto {
  display: flex;
  margin: 10px 0 0;
  text-align: center;
  flex-direction: column;
}
.box-btn-lotto1 {
  display: flex;
  margin: 10px 0 0;
  text-align: center;
}

.btn-rule-lotto,
.btn-play-lotto {
  color: #fff;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 8px 10px;
  -webkit-flex: 1 1;
  flex: 1 1;
  margin: 0 5px 5px;
  cursor: pointer;
}

.btn-rule-lotto,
.btn-play-lotto {
  background-color: #068153;
}

.btn-white-lotto {
  background-color: #ffffff;
  color: #676767;
  outline: none;
  border: none;
  padding: 0;
  -webkit-flex: 1 1;
  flex: 1 1;
  margin: 0 5px;
}

.btn-prize {
  background-color: #068153;
  border-radius: 8px;
  color: #fff;
  padding: 0 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 5px;
  box-shadow: 0 3px 6px #af9d9f;
}

.font-size-12 {
  /* font-size: 10px; */
  font-size: 12px;
}
.font-size-14 > span {
  font-size: 14px;
  font-weight: 400;
}

.layout-lottoplay {
  color: #fff;
  position: relative;
  padding: 10px 15px 80px;
}
.relative-lottoplay {
  position: relative;
}
.container-lottoplay {
  padding-top: 20px;
  width: 100%;
}

.box-top-lottoplay {
  background: #fff;
  color: #676767;
  width: 100%;
  padding: 5px 10px;
  border-radius: 10px;
}


.box-bottom-lottoplay {
  background: #fff;
  color: #676767;
  width: 100%;
  padding: 0px 10px 20px;
  margin: 10px 0;
  border-radius: 10px;
  background-color: #fff;
  /* padding: 20px; */
  border-radius: 5px 5px 5px 5px;
  color: #676767;
  box-shadow: 0 3px 6px #d4d4d4;
}

.box-lottolist-lottoplay {
  display: -webkit-flex;
  display: flex;
  font-size: 14px;
}

.box-main-lottoplay {
  display: -webkit-flex;
  display: flex;
}

.flex-lottoplay {
  display: -webkit-flex;
  display: flex;
}

.align-items-lottoplay {
  -webkit-align-items: center;
  align-items: center;
}

.flex-1-lottoplay {
  -webkit-flex: 1 1 25%;
  flex: 1 1 25%;
}

.font-09-lottoplay {
  font-size: 80%;
}

.font-06-lottoplay {
  font-size: 0.6em;
}

.flex-lottoplay {
  display: -webkit-flex;
  display: flex;
}

.box-time-lottoplay {
  background: #f6d110;
  padding: 5px 30px;
  border-radius: 10px;
  color: #111;
  min-width: 180px;
}

.margin10-lottoplay {
  margin: 0 4px;
}

.img-thai-lottoplay {
  margin-right: 5px;
  width: 32px;
  height: 32px;
}

.box-lottolist-lottoplay {
  display: -webkit-flex;
  display: flex;
  font-size: 14px;
}

.box-button-showhide-lottoplay,
.list-lottoplay {
  text-align: center;
  margin: auto;
  box-shadow: 0 3px 6px #d4d4d4;
}

.list-lottoplay {
  -webkit-flex: 3 1;
  flex: 3 1;
  background-color: #fff;
  color: #676767;
  font-weight: 700;
  padding: 10px;
  border: none;
  border-radius: 100px;
}

.box-main-LottoPlay {
  display: -webkit-flex;
  display: flex;
}

.widthLottoPlayArea {
  width: 100%;
}

.box-btn-lotto a {
  /* display: flex; */
  flex: 1 1;
  text-decoration: none;
}

.box-play-LottoPlay {
  margin: 10px 5px 0;
}

.flex-wrap-LottoPlay {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin-bottom: 5px;
}

.flex-LottoPlay {
  display: -webkit-flex;
  display: flex;
}

.btn-select-left-LottoPlay,
.btn-select-right-LottoPlay {
  background: none;
  color: #214c05;
  border: 1.5px solid #214c05;
  padding: 8px;
  outline: none;
  min-width: 30%;
  margin: 2px;
  border-radius: 5px;
}

.active-LottoPlay {
  min-width: 30%;
  background: #214c05 !important;
  color: #fff !important;
  outline: none;
  margin: 2px;
  border-radius: 5px;
}

.btn-select-LottoPlay {
  border: 1.5px solid #214c05;
  background: none;
  color: #214c05;
  padding: 8px;
  outline: none;
  min-width: 30%;
  margin: 2px;
  border-radius: 5px;
}

.btn-select-left-LottoPlay,
.btn-select-right-LottoPlay {
  background: none;
  color: #214c05;
  border: 1.5px solid #214c05;
  padding: 8px;
  outline: none;
  min-width: 30%;
  margin: 2px;
  border-radius: 5px;
}

.box-number-LottoPlay {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px 5px 5px 5px;
  color: #676767;
  box-shadow: 0 3px 6px #d4d4d4;
}

.line-bottom-LottoPlay {
  border-bottom: 1.2px solid #676767;
  font-size: 14px;
  padding: 5px 0;
}

.flex-wrap-LottoPlay {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin-bottom: 5px;
}

.flex-1-LottoPlay {
  -webkit-flex: 1 1 25%;
  flex: 1 1 25%;
}

.left-border-LottoPlay {
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  border-left: 1px solid #214c05;
}

.btn-select-play-action-LottoPlay {
  background: #214c05 !important;
  color: #fff !important;
  outline: none;
  min-width: 30%;
  margin-bottom: 5px;
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
}

.btn-select-play-LottoPlay,
.btn-select-play-LottoPlay-active {
  border: 1.5px solid #214c05;
  padding: 5px;
  outline: none;
  min-width: 30%;
  margin-bottom: 5px;
}

.right-border-LottoPlay {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  border-right: 1px solid #214c05;
}

.btn-select-play-LottoPlay {
  background: none;
  color: #214c05;
}

.font-09-LottoPlay {
  font-size: 80%;
}

.box-click-LottoPlay,
.box-click-select-LottoPlay {
  background: #efefef;
  border-radius: 10px;
  margin-top: 10px;
  box-shadow: 0 3px 6px #d4d4d4;
}

.box-click-LottoPlay {
  padding: 15px;
}

.center-LottoPlay {
  text-align: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.btn-number-play-LottoPlay,
.btn-number-play-LottoPlay-1 {
  width: 70px;
  padding: 5px 10px;
  font-weight: 700;
  font-size: 1.2em;
  margin: 3px;
}

.btn-number-play-LottoPlay-1 {
  background: #999;
  border-radius: 10px;
}

.playBtn {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  padding: 10px 10px 0;
}

.btn-nonesentpost-LottoPlay,
.btn-nonesentpost-LottoPlay:disabled {
  background: #676767;
  margin-top: 10px;
  border: 0;
  border-radius: 100px;
  padding: 10px;
  color: #fff;
}

.btn-sentpost-LottoPlay,
.btn-sentpost-LottoPlay:disabled {
  background: #214c05;
  margin-top: 3px;
  border: 0;
  border-radius: 100px;
  padding: 10px;
  color: #fff;
}

/* outcs */

@media screen and (max-width: 480px) {
  .img-closed-error-pages {
    width: 80% !important;
  }
}

.btn-error-pages {
  text-align: center;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  /* background-color: red; */
  font-size: 14px;
  color: white;
  border: none;
  font-size: 16px;
  background-color: #214c05;
  /* border: 3px solid red; */
}

.body-error-pages {
  max-width: 850px;
  margin: 0 auto;
  background: rgb(39, 39, 39);
  font-family: "Prompt";
  height: 100vh;
}

.container-closed-error-pages {
  font-family: "prompt";
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #efefef;
  color: #676767;
  overflow: scroll;
}

.img-closed-error-pages {
  width: 50%;
  margin: 10px 0px;
}

.img-banner-error-pages {
  margin-top: 0;
  width: 100%;
  /* margin: 10px 0px; */
}

.content-error-pages {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  margin: 20px;
}

.Header-error-pages {
  /* background: #214C05; */
  background: rgb(126, 0, 0);
  background: linear-gradient(
    180deg,
    rgb(97, 0, 0) 0%,
    rgb(153, 0, 0) 56%,
    rgba(189, 0, 0, 1) 86%,
    rgb(221, 0, 0) 100%
  );
  width: 100%;
  padding: 10px 0px;
  max-width: 850px;
  color: white;
  font-size: 0.9em;
  z-index: 999;
}

.font8-error-pages {
  font-size: 0.8em;
}

.Footer {
  background: #efefef;
  position: relative;
  bottom: 70px;
  width: 100%;
  max-width: 850px;
  padding: 10px;
  color: #b2b2b2;
  text-align: center;
  font-size: 0.9em;
  border-top: 1px solid #e1e1e1;
}

.Footer-error-pages {
  background: #efefef;
  bottom: 0;
  max-width: 850px;
  color: #b2b2b2;
  text-align: center;
  font-size: 0.9em;
  border-top: 1px solid #e1e1e1;
  padding: 10px 0px;
}

.color-red-footer-error-pages {
  color: #214c05;
}

.color-wed-footer {
  color: #214c05;
}

.font08-footer {
  font-size: 0.8em;
  line-height: 2em;
}
.font08-footer-error-pages {
  font-size: 0.8em;
}

.btn-contact-error-pages {
  border: none;
  background-color: #33bf10;
  color: white;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  font-size: 16px;
  /* font-family: 'Prompt'; */
  outline: none;
  cursor: pointer;
}

/* rut77 */

.box-animal-lotto {
  display: flex;
  margin: 0;
  text-align: center;
  flex-wrap: nowrap;
  background-color: #fff;
  justify-content: space-around;
  font-size: 12px;
}
.charity {
  max-width: 80px;
  line-height: 100px;
  padding: 8px 5px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}
.charity1 {
  width: 90px;
  line-height: 100px;
  padding: 8px 5px;
  border-radius: 5px;
}

.lottoHeight1 {
  height: 205px;
}
.lottoHeight2 {
  height: 30px;
}
.kbank {
  width: 30px;
  margin-right: 20px;
}

.my-modal .modal-content {
  background-color: red;
}

@media (max-width: 500px) {
  .lottoHeight2 {
    height: 30px;
  }
}

.font12 {
  font-size: 12px;
  /* margin-bottom: 0.5rem !important; */
  color: black;
}



/* grid */
.grid-container {
  display: grid;
  gap: 5px;
  background-color: #fff;
  padding: 5px;
}

.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding: 1px;
  font-size: 30px;
}

.item1 {
  grid-column: 1;
  grid-row: 1 / span 2;
  background-color: #f6f6f6;
  box-shadow: 0 3px 6px #d4d4d4;
  font-size: 16px;
  color: #214c05;

}
.item11 {
  grid-column: 1;
  grid-row: 1 / span 2;
  background-color: #ffffff;
  box-shadow: 0 3px 6px #d4d4d4;
  font-size: 16px;
  color: #214c05;

}

.item1 p {
  margin-bottom: 0rem;
}

.item2 {
  grid-column: 2 / span 2;
  grid-row: 1;
  color: #214c05;
}

.item3 {
  background: #fff;
  border-radius: 10px;
  color: #000;

}

.item3 p{
  /* margin-bottom: 0.5rem; */
  background: #2aa500;
  border-radius: 10px;
  /* margin-top: 1rem; */
  margin: 0rem 0rem 0.5rem;
  color: #fff;
  box-shadow: 0 3px 6px #d4d4d4;
}

.item4 {
  min-width: 100px;
  background: #f6d110;
  border-radius: 10px;
  color: #111;
  padding-top: 20px;
  box-shadow: 0 3px 6px #d4d4d4;
}

.datetimeCharity {
  /* background: #214c05; */
  border: 0;
  padding: 5px;
  background: #2aa500;
  border-radius: 10px;
  box-shadow: 0 3px 6px #d4d4d4;
  font-size: 14px;
  color: #ffffff;
}

p.green {
  color: green;
}


.font77 {
  background: #214c05;
  border-radius: 10px;
  margin: 0rem 0rem;
  color: #fff;
  box-shadow: 0 3px 6px #d4d4d4;
  padding: 0 0.5rem;
  font-size: 12px;
}

.font7 {
  background: #214c05;
  border-radius: 10px;
  margin: 0rem 0rem;
  color: #fff;
  box-shadow: 0 3px 6px #d4d4d4;
  padding: 0 0.5rem;
}

.font12_400 {
  font-size: 12px;
  font-weight: 400;
}
.font7y {
  background: #FFC107;
  border-radius: 10px;
  margin: 0rem 0rem;
  color: #111;
  box-shadow: 0 3px 6px #d4d4d4;
  padding: 0 0.5rem;
}

.p5 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.p9 {
  padding: 0 0.9rem;
}


.fontcenter {
  text-align: center;
}

.login-thankyou {
  width: 150px;
  height: auto;
  padding-bottom: 20px;
}

.login-thank {
  text-align: center;
}


.flex-item {
  flex-grow: 1;
  text-align: center;
  margin: 5px;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.refresh {
  font-size: 18px;
  padding-right: 20px;
  padding-top: 2px;
}