.kbank {
    width: 30px;
    margin-right: 20px;
}

.my-modal .modal-content {
  background-color: red;
}




/* YourComponent.css */

select {
    /* Add your select styles here */
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  option {
    /* Add your option styles here */
    padding: 8px 30px 8px 10px; /* Adjust the padding as needed */
    background-repeat: no-repeat;
    background-position: 5px center; /* Adjust the position of the image */
    background-size: 20px; /* Adjust the size of the image */
  }
  